@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --primary: #18181B;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --radius: 0.75rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
  input {
    @apply bg-white text-gray-900 !important;
  }
  select {
    @apply bg-white text-gray-900 !important;
  }
  textarea {
    @apply bg-white text-gray-900 !important;
  }
}

/* Custom range input styling */
input[type="range"] {
  @apply appearance-none bg-transparent cursor-pointer;
  height: 6px;
}

input[type="range"]::-webkit-slider-thumb {
  @apply appearance-none w-6 h-6 rounded-full bg-primary border-4 border-white cursor-pointer shadow-lg;
  margin-top: -10px;
}

input[type="range"]::-moz-range-thumb {
  @apply w-6 h-6 rounded-full bg-primary border-4 border-white cursor-pointer shadow-lg;
}

input[type="range"]::-webkit-slider-runnable-track {
  @apply w-full h-2 rounded-full bg-gray-200;
}

input[type="range"]::-moz-range-track {
  @apply w-full h-2 rounded-full bg-gray-200;
}

/* Ensure the track is visible and clickable */
input[type="range"]::-webkit-slider-runnable-track {
  @apply cursor-pointer;
}

input[type="range"]::-moz-range-track {
  @apply cursor-pointer;
}

/* Remove default focus styles */
input[type="range"]:focus {
  outline: none;
}

/* Add custom focus styles */
input[type="range"]:focus::-webkit-slider-thumb {
  @apply ring-2 ring-primary ring-offset-2;
}

input[type="range"]:focus::-moz-range-thumb {
  @apply ring-2 ring-primary ring-offset-2;
}

/* Glass effect */
.glass {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.07);
}

/* Elegant transitions */
.transition-elegant {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Hover effects */
.hover-lift {
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
}

.hover-lift:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.1);
}

/* Elegant gradients */
.gradient-elegant {
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 100%);
}

.gradient-elegant-light {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.95) 100%);
}

/* Navigation button styles */
.nav-button {
  @apply bg-white text-gray-900 hover:bg-gray-100 transition-colors;
}

/* Contact form specific styles */
.contact-form input,
.contact-form textarea,
.contact-form select {
  @apply bg-white text-gray-900 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary/20 !important;
}